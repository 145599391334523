import React, {useEffect, useState} from "react";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Grid, TextField, Typography} from "@mui/material";
import ButtonGroup from '@mui/material/ButtonGroup';
import Footer from "../../components/footer/Footer";
import {useNavigate, useSearchParams} from 'react-router-dom';
import "./OneShot.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import {styled, useTheme} from '@mui/material/styles';
import Button from '@mui/material/Button';
import {getCookie, setCookie} from '../../utils/Functions';
import Header from "../../components/header/Header";

import Slide from '@mui/material/Slide';
//Images
import fondoDonacion from "../../assets/imgs/unicef/OneShot/OneShotFeb13.png"
import mobileFondoDonacion from "../../assets/imgs/unicef/OneShot/mobileOneShotFeb13.png"
import fondoPrincipal from "../../assets/imgs/unicef/OneShot/OneShotFeb13.png"
import mobileFondoPrincipal from "../../assets/imgs/unicef/OneShot/mobileOneShotFeb13.png"



const ColorButton = styled(Button)(({theme}) => ({
    color: theme.palette.getContrastText("#fff"),
    fontSize: 20,
    fontWeight: "700",
    padding: 1,
    height: 65,
    borderRadius: 3,
    width: "100%",
    backgroundColor: "#ffff00",
    '&:hover': {
        backgroundColor: "#fff",
        color: "#000"
    },
}));

const OneShot = (props) => {


    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [showAmount, setShowAmount] = useState(false);
    const [error, setError] = useState("");
    const [isFirstClick, setIsFirstClick] = useState(true);

    const [amount, setAmount] = useState(120);
    const [imgDonacion, setImgDonacion] = useState(false);
    const [searchParams] = useSearchParams();

    const [finalData, setFinalData] = useState({


        utm_source: searchParams.get("utm_source") ? searchParams.get("utm_source") : "false",
        utm_medium: searchParams.get("utm_medium") ? searchParams.get("utm_medium") : "false",
        utm_campaign: searchParams.get("utm_campaign") ? searchParams.get("utm_campaign") : "false",
        utm_id: searchParams.get("utm_id") ? searchParams.get("utm_id") : "false",
        utm_term: searchParams.get("utm_term") ? searchParams.get("utm_term") : "false",
        utm_content: searchParams.get("utm_content") ? searchParams.get("utm_content") : "false"
    })

    const handleChange = (event) => {

        if (event.target.value === "") {
            setAmount(event.target.value)
        } else {

            if (/^[0-9]+$/.test(event.target.value)) {
                //console.log("mal")
                setError("")
                setAmount(event.target.value)
                if (event.key === 'Enter') {
                    //console.log("vaaa")
                    donate(event)
                }
            }
        }
    };
    const donate = (event) => {
        //console.log("donate")
        if (parseInt(amount) >= 100) {
            if (process.env.REACT_APP_URL_CONFIG === "https://www.unicef.org.bo") {
                window.fbq('track', 'AddToCart', {
                    content_type: 'product',
                    value: amount,
                    currency: "BOB"
                });
                window.gtag("event", "add_to_cart", {
                    currency: "BOB",
                    value: amount,
                    items: [
                        {
                            item_id: "SKU_DONACION",
                            item_name: "Donación de " + amount + "Bs",
                            price: amount,
                            quantity: 1
                        }
                    ]
                });
            } else {
                console.log("NO TAGS dev mode")
            }

            setTimeout(() => {

                window.scrollTo(0, 0)
            }, 100);
            navigate("/donacion-unica-completar/" + amount);
        } else {
            setError("El monto debe ser mayor a Bs 100")
        }
    }

    useEffect(() => {

        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 100);

        let utm_source = ""
        let utm_id = ""
        let utm_content = ""
        let utm_medium = ""
        let utm_campaign = ""
        let utm_term = ""
        let _finalData = finalData

        if (searchParams.get("utm_source")) {
            utm_source = searchParams.get("utm_source")
            console.log("siutm_source", utm_source)
            _finalData["utm_source"] = utm_source
        } else {
            utm_source = getCookie(process.env.REACT_APP_NAME + "utm_source")
            if (!utm_source || utm_source === "undefined" || utm_source === undefined || utm_source === null || utm_source === "null") {
                utm_source = ""
            }
            _finalData["utm_source"] = utm_source
        }


        if (searchParams.get("utm_term")) {
            _finalData["utm_term"] = utm_term
            utm_term = searchParams.get("utm_term")
        } else {
            utm_term = getCookie(process.env.REACT_APP_NAME + "utm_term")
            if (!utm_term || utm_term === "undefined" || utm_term === undefined || utm_term === null || utm_term === "null") {
                utm_term = ""
            }
            utm_term = searchParams.get("utm_term")
        }

        if (searchParams.get("utm_id")) {
            utm_id = searchParams.get("utm_id")
            _finalData["utm_id"] = utm_id
        } else {
            utm_id = getCookie(process.env.REACT_APP_NAME + "utm_id")
            if (!utm_id || utm_id === "undefined" || utm_id === undefined || utm_id === null || utm_id === "null") {
                utm_id = ""
            }
            _finalData["utm_id"] = utm_id
        }

        if (searchParams.get("utm_content")) {
            utm_content = searchParams.get("utm_content")
            _finalData["utm_content"] = utm_content
        } else {
            utm_content = getCookie(process.env.REACT_APP_NAME + "utm_content")
            if (!utm_content || utm_content === "undefined" || utm_content === undefined || utm_content === null || utm_content === "null") {
                utm_content = ""
            }
            _finalData["utm_content"] = utm_content
        }

        if (searchParams.get("utm_medium")) {
            utm_medium = searchParams.get("utm_medium")
            _finalData["utm_medium"] = utm_medium
        } else {
            utm_medium = getCookie(process.env.REACT_APP_NAME + "utm_medium")
            if (!utm_medium || utm_medium === "undefined" || utm_medium === undefined || utm_medium === null || utm_medium === "null") {
                utm_medium = ""
            }
            _finalData["utm_medium"] = utm_medium
        }

        if (searchParams.get("utm_campaign")) {
            utm_campaign = searchParams.get("utm_campaign")
            _finalData["utm_campaign"] = utm_campaign
        } else {
            utm_campaign = getCookie(process.env.REACT_APP_NAME + "utm_campaign")
            if (!utm_campaign || utm_campaign === "undefined" || utm_campaign === undefined || utm_campaign === null || utm_campaign === "null") {
                utm_campaign = ""
            }
            _finalData["utm_campaign"] = utm_campaign
        }


        setTimeout(() => {
            setFinalData(_finalData)
        }, 100);

        //console.log(_finalData)

        setCookie(process.env.REACT_APP_NAME + "utm_source", utm_source, 0.5)
        setCookie(process.env.REACT_APP_NAME + "utm_id", utm_id, 0.5)
        setCookie(process.env.REACT_APP_NAME + "utm_content", utm_content, 0.5)
        setCookie(process.env.REACT_APP_NAME + "utm_medium", utm_medium, 0.5)
        setCookie(process.env.REACT_APP_NAME + "utm_campaign", utm_campaign, 0.5)
        setCookie(process.env.REACT_APP_NAME + "utm_term", utm_term, 0.5)

    })

    return (
        <>
            <div id="donation-form">
                <>
                    {fullScreen && (
                        <Header/>
                    )}
                    <section className="home-slider position-relative">
                        <img
                            src={fullScreen ? (imgDonacion ? mobileFondoDonacion : mobileFondoPrincipal) : (imgDonacion ? fondoDonacion : fondoPrincipal)}
                            alt='image'/>
                        {fullScreen && imgDonacion && false && (
                            <div className="donation-legend" style={{
                                zIndex: 800,
                                position: "absolute",
                                padding: "23px 10px",
                                bottom: "20px",
                                maxWidth: "50%"
                            }}>

                                <div>
                                    <p style={{
                                        fontSize: "1.5rem",
                                        color: "#ffffff",
                                        lineHeight: 1.1,
                                        textAlign: "left",
                                        paddingLeft: "20px"
                                    }}
                                       className={"font-face-UniversBold"}>Con {amount} Bs</p>
                                    <p style={{
                                        fontSize: "1rem",
                                        color: "#ffffff",
                                        lineHeight: 1.1,
                                        textAlign: "left",
                                        paddingLeft: "20px"
                                    }}
                                       className={"font-face-UniversLight"}>lograr&aacute;s transformar vidas</p>
                                </div>
                            </div>
                        )}
                        {!fullScreen && imgDonacion && false && (
                            <div style={{
                                flex: "50%",
                                position: "absolute",
                                bottom: "15%",
                                right: "5%"
                            }}>

                                <div>
                                    <p style={{
                                        fontSize: "4rem",
                                        color: "#ffffff",
                                        lineHeight: 1.1,
                                        textAlign: "right"
                                    }}
                                       className={"font-face-UniversRegular"}>Con {amount} Bs</p>
                                    <p style={{
                                        fontSize: "2rem",
                                        color: "#ffffff",
                                        lineHeight: 1.1,
                                        textAlign: "right"
                                    }}
                                       className={"font-face-UniversLight"}>lograr&aacute;s transformar vidas</p>
                                </div>

                            </div>
                        )}
                        {(
                            <div>
                                <div style={!fullScreen ? {
                                    zIndex: 9999,
                                    display: "flex",
                                    flexDirection: "column",
                                    position: "absolute",
                                    bottom: "25%",
                                    paddingLeft: "6%",
                                    maxWidth: "40%",
                                    padding: "23px 25px"
                                } : {
                                    display: "flex",
                                    position: "absolute",
                                    top: "55%",
                                    width: "82%",
                                    padding: "23px 10px"
                                }}>


                                    {!fullScreen && (
                                        <div class={"row"} style={{flex: !fullScreen ? "50%" : "100%",}}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        sx={{mb: {xs: 4, md: 3, lg: 2}, mt: {xs: 4, md: 3, lg: 2}}}
                                                        variant={fullScreen ? "h3" : "h4"}
                                                        style={{
                                                            textAlign: fullScreen ? "center" : "left",
                                                            color: fullScreen ? "#000" : "#000",
                                                            background: "#FFFA2F",
                                                        }}>

                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={3} xs={3}>
                                                    <ColorButton style={{
                                                        color: "#fff",
                                                        borderRadius: 45,
                                                        textTransform: "capitalize",
                                                        border: "2px solid #fff",
                                                        backgroundColor: (amount === 100 || isFirstClick) ? "rgb(9,136,199,1)" : "rgb(9,136,199,0.5)",
                                                    }} selected onClick={() => {
                                                        setError("");
                                                        setAmount(100);
                                                        setIsFirstClick(false);
                                                        setShowAmount(false);
                                                        setImgDonacion(true)
                                                    }} variant="contained"><span
                                                        className={"font-face-UniversBold"}>Bs 100</span></ColorButton>
                                                </Grid>

                                                <Grid item lg={3} xs={3}>
                                                    <ColorButton style={{
                                                        color: "#fff",
                                                        borderRadius: 45,
                                                        textTransform: "capitalize",
                                                        border: "2px solid #fff",
                                                        backgroundColor: (amount === 150 || isFirstClick) ? "rgb(9,136,199,1)" : "rgb(9,136,199,0.5)",
                                                    }} onClick={() => {
                                                        setError("");
                                                        setAmount(150);
                                                        setIsFirstClick(false);
                                                        setShowAmount(false);
                                                        setImgDonacion(true)
                                                    }} variant="contained"><span
                                                        className={"font-face-UniversBold"}>Bs 150</span></ColorButton>
                                                </Grid>
                                                <Grid item lg={3} xs={3}>
                                                    <ColorButton style={{
                                                        color: "#fff",
                                                        borderRadius: 45,
                                                        textTransform: "capitalize",
                                                        border: "2px solid #fff",
                                                        backgroundColor: (amount === 200 || isFirstClick) ? "rgb(9,136,199,1)" : "rgb(9,136,199,0.5)",
                                                    }} onClick={() => {
                                                        setError("");
                                                        setAmount(200);
                                                        setIsFirstClick(false);
                                                        setShowAmount(false);
                                                        setImgDonacion(true)
                                                    }} variant="contained"><span
                                                        className={"font-face-UniversBold"}>Bs 200</span></ColorButton>
                                                </Grid>
                                                <Grid item lg={3} xs={3}>
                                                    <ColorButton
                                                        style={{
                                                            color: "#fff",
                                                            borderRadius: 45,
                                                            textTransform: "capitalize",
                                                            border: "2px solid #fff",
                                                            backgroundColor: (amount === 200 || isFirstClick) ? "rgb(9,136,199,1)" : "rgb(9,136,199,0.5)",
                                                            fontSize: "1.1rem",
                                                            lineHeight: "1"
                                                        }} onClick={() => {
                                                        setAmount(200);
                                                        setIsFirstClick(false);
                                                        setShowAmount(true);
                                                        setImgDonacion(true)
                                                    }} variant="contained"><span className={"font-face-UniversBold"}>Otro Monto Mayor</span></ColorButton>
                                                </Grid>
                                                <Grid item lg={12} xs={12}

                                                      sx={{mb: {xs: 4, md: 3, lg: 2}, mt: {xs: 4, md: 2, lg: 1}}}

                                                >
                                                </Grid>

                                                <Grid item lg={12} xs={12} mt={1} style={{textAlign: "center"}}>
                                                    {showAmount ?
                                                        <ButtonGroup
                                                            style={{width: "100%"}}
                                                            disableElevation
                                                            variant="contained"
                                                            aria-label="Disabled elevation buttons"
                                                        >
                                                            <TextField style={{width: "50%"}}
                                                                       className="amount-text"
                                                                       value={amount}
                                                                       onChange={handleChange}
                                                                       onKeyDown={handleChange}/>
                                                            <ColorButton onClick={donate} style={{
                                                                width: "50%",
                                                                backgroundColor: "#FFFA2F",
                                                                color: "#000"
                                                            }}><span
                                                                className={"font-face-UniversBold"}>Dona Ahora</span></ColorButton>
                                                        </ButtonGroup>
                                                        :
                                                        <ColorButton style={{
                                                            borderRadius: 35, backgroundColor: "#FFFA2F",
                                                            color: "#000", width: fullScreen ? "50%" : "60%"
                                                        }}
                                                                     onClick={donate}>Dona Ahora</ColorButton>
                                                    }
                                                </Grid>

                                                {error !== "" &&
                                                    <Grid item lg={12} xs={12} mt={1}>
                                                        <div style={{
                                                            background: "#000",
                                                            zIndex: "99",
                                                            fontSize: "1rem",
                                                            fontWeight: 600,
                                                            textAlign: "center",
                                                            color: "#fff",
                                                            padding: 5,
                                                            borderRadius: 5
                                                        }}>
                                                            <WarningAmberIcon variant="h6"
                                                                              sx={{fontSize: "2rem", marginRight: 1}}/>
                                                            {error}
                                                        </div>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </section>
                </>
            </div>
            {fullScreen && (
                <>
                    <div className="donation-form"
                         style={fullScreen ? {width: "100%", padding: "0"} : {left: "5%", width: 512, zIndex: 999999, top: "350px"}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} style={{backgroundColor: "#FFFA2F", width: "100%"}}>
                                <Typography
                                    sx={{mb: {xs: 4, md: 3, lg: 2}, mt: {xs: 4, md: 3, lg: 2}}}
                                    variant={fullScreen ? "h3" : "h4"}
                                    style={{
                                        textAlign: fullScreen ? "center" : "left",
                                        color: fullScreen ? "#000" : "#000",
                                        marginTop: "16px",
                                        marginBottom: "16px",

                                    }}>
                                <span
                                    className={fullScreen ? "font-face-UniversBold" : "font-face-UniversLight"}
                                >
                                    Selecciona el monto que quieres donar.
                                </span>
                                </Typography>
                            </Grid>
                            <Grid item lg={3} xs={3}>
                                <ColorButton style={{
                                    color: "#fff",
                                    borderRadius: 45,
                                    textTransform: "capitalize",
                                    border: "2px solid #fff",
                                    backgroundColor: amount === 100 ? "rgb(9,136,199,1)" : "rgb(9,136,199,0.5)",
                                    marginTop: "8px"
                                }} selected onClick={() => {
                                    setError("");
                                    setAmount(100);
                                    setShowAmount(false);
                                    setImgDonacion(true)
                                }} variant="contained"><span
                                    className={"font-face-UniversBold"}>Bs 100</span></ColorButton>
                            </Grid>

                            <Grid item lg={3} xs={3}>
                                <ColorButton style={{
                                    color: "#fff",
                                    borderRadius: 45,
                                    textTransform: "capitalize",
                                    border: "2px solid #fff",
                                    backgroundColor: amount === 150 ? "rgb(9,136,199,1)" : "rgb(9,136,199,0.5)",
                                    marginTop: "8px"
                                }} onClick={() => {
                                    setError("");
                                    setAmount(150);
                                    setShowAmount(false);
                                    setImgDonacion(true)
                                }} variant="contained"><span
                                    className={"font-face-UniversBold"}>Bs 150</span></ColorButton>
                            </Grid>
                            <Grid item lg={3} xs={3}>
                                <ColorButton style={{
                                    color: "#fff",
                                    borderRadius: 45,
                                    textTransform: "capitalize",
                                    border: "2px solid #fff",
                                    backgroundColor: amount === 200 ? "rgb(9,136,199,1)" : "rgb(9,136,199,0.5)",
                                    marginTop: "8px"
                                }} onClick={() => {
                                    setError("");
                                    setAmount(200);
                                    setShowAmount(false);
                                    setImgDonacion(true)
                                }} variant="contained"><span
                                    className={"font-face-UniversBold"}>Bs 200</span></ColorButton>
                            </Grid>
                            <Grid item lg={3} xs={3}>
                                <ColorButton
                                    style={{
                                        color: "#fff",
                                        borderRadius: 45,
                                        textTransform: "capitalize",
                                        border: "2px solid #fff",
                                        backgroundColor: amount !== 100 && amount !== 150 && amount !== 200 ? "rgb(9,136,199,1)" : "rgb(9,136,199,0.5)",
                                        fontSize: "1.1rem",
                                        lineHeight: "1",
                                        marginTop: "8px"
                                    }} onClick={() => {
                                    setAmount(200);
                                    setShowAmount(true);
                                    setImgDonacion(true)
                                }} variant="contained"><span className={"font-face-UniversBold"}>Otro Monto Mayor</span></ColorButton>
                            </Grid>

                            <Grid item lg={12} xs={12} mt={1} style={{textAlign: "center"}}>
                                {showAmount ?
                                    <ButtonGroup
                                        style={{width: "100%"}}
                                        disableElevation
                                        variant="contained"
                                        aria-label="Disabled elevation buttons"
                                    >
                                        <TextField style={{width: "50%"}}
                                                   className="amount-text"
                                                   value={amount}
                                                   onChange={handleChange}
                                                   onKeyDown={handleChange}/>
                                        <ColorButton onClick={donate} style={{width: "50%",
                                            backgroundColor: "#FFFA2F",
                                            color:"#000"}}><span
                                            className={"font-face-UniversBold"}>Dona Ahora</span></ColorButton>
                                    </ButtonGroup>
                                    :
                                    <ColorButton style={{borderRadius: 35, width: fullScreen ? "50%" : "60%",backgroundColor: "#FFFA2F",
                                        color:"#000"}}
                                                 onClick={donate}>Dona Ahora</ColorButton>
                                }
                            </Grid>

                            {error !== "" &&
                                <Grid item lg={12} xs={12} mt={1}>
                                    <div style={{
                                        background: "#000",
                                        zIndex: "99",
                                        fontSize: "1rem",
                                        fontWeight: 600,
                                        textAlign: "center",
                                        color: "#fff",
                                        padding: 5,
                                        borderRadius: 5
                                    }}>
                                        <WarningAmberIcon variant="h6" sx={{fontSize: "2rem", marginRight: 1}}/>
                                        {error}
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </div>
                    <Footer/>
                </>
            )}

        </>
    );
}

OneShot.propTypes = {};

export default OneShot;
